<template>
  <BCard>
    <div class="flex flex-row justify-between">
      <div class="flex flex-row items-center gap-7">
        <img
          src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
          alt="Komerce"
          class="cursor-pointer"
          @click="$router.back()"
        >
        <div class="text-black font-medium text-2xl">
          Detail Berkas
        </div>
      </div>
      <div class="d-flex gap-5">
        <div v-if="data.status === 'requested'">
          <BButton
            variant="outline-primary"
            size="small"
            style="border-radius: 25px !important"
            @click="$bvModal.show('modal-rules')"
          >
            Petunjuk Verifikasi
            <BIcon icon="question-circle" />
          </BButton>
        </div>
        <div
          v-else
          class="flex items-center gap-4"
        >
          <div>{{ DAY_MONTH_YEAR(data.requested_date) }} | {{ TIME(data.requested_date) }}</div>
          <div :class="getClassStatus(data.status)">
            {{ getLabelStatus(data.status) }}
          </div>
        </div>
        <a
          :href="`https://analytics.komerce.id/dashboard/3-data-partners?tab=8-orders&partner_id=${partnerInfo.partner_user_id}`"
          target="_blank"
          :class="data.status === 'requested' ? 'btn' : ''"
          class="px-1 py-[5px] text-center text-white rounded-md bg-primary cursor-pointer"
        >
          Lihat Data
        </a>
      </div>
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <ValidationObserver #default="{ invalid }">
        <BRow>
          <BCol lg="6">
            <BCard body-class="flex flex-col gap-3">
              <div>
                <div class="text-black text-xl font-medium">
                  Foto wajah beserta KTP
                  <span :class="getClass(data.ktp_face_picture.status)">
                    ({{ getLabelStatus(data.ktp_face_picture.status) }})
                  </span>
                </div>
              </div>
              <img
                :src="data.ktp_face_picture.url"
                alt="Komerce"
                class="rounded-xl"
              >
              <span v-if="data.ktp_face_picture.status === 'requested'">
                <div class="text-sm text-center my-1">
                  <ValidationProvider
                    #default="{ errors }"
                    rules="required"
                  >
                    <BFormRadioGroup
                      v-model="selected.selfie"
                      :state="errors.length > 0 ? false : null"
                      :options="options"
                      @change="handleChange($event)"
                    />
                  </ValidationProvider>
                </div>
                <BFormGroup
                  invalid-feedback="Silahkan isi catatan"
                  :state="state.selfie"
                >
                  <label
                    for="reason"
                    class="text-lg text-black"
                  >
                    Alasan
                  </label>
                  <ValidationProvider
                    #default="{ errors }"
                    :rules="selected.selfie === false ? 'required' : ''"
                  >
                    <BFormTextarea
                      id="reason"
                      v-model="selfie"
                      debounce="300"
                      placeholder="Tuliskan alasan kamu jika menolak"
                      rows="4"
                      :formatter="maxLength"
                      :state="errors.length > 0 || state.selfie ? false : null"
                    />
                  </ValidationProvider>
                  <BRow class="justify-content-end">
                    <small class="mr-1 mt-50">
                      {{ selfie.length }}/300 karakter
                    </small>
                  </BRow>
                </BFormGroup>
              </span>
              <BFormTextarea
                v-else-if="data.ktp_face_picture.status === 'rejected'"
                v-model="data.ktp_face_picture.notes"
                disabled
                rows="4"
                class="mt-1"
              />
            </BCard>
          </BCol>
          <BCol lg="6">
            <BCard body-class="flex flex-col gap-3">
              <div>
                <div class="text-black text-xl font-medium">
                  Foto KTP
                  <span :class="getClass(data.ktp_picture.status)">
                    ({{ getLabelStatus(data.ktp_picture.status) }})
                  </span>
                </div>
              </div>
              <img
                :src="data.ktp_picture.url"
                alt="Komerce"
                class="rounded-xl"
              >
              <span v-if="data.ktp_picture.status === 'requested'">
                <div class="text-sm text-center my-1">
                  <ValidationProvider
                    #default="{ errors }"
                    rules="required"
                  >
                    <BFormRadioGroup
                      v-model="selected.idCard"
                      :state="errors.length > 0 ? false : null"
                      :options="options"
                      @change="handleChange($event)"
                    />
                  </ValidationProvider>
                </div>
                <BFormGroup
                  invalid-feedback="Silahkan isi catatan"
                  :state="state.idCard"
                >
                  <label
                    for="reason"
                    class="text-lg text-black"
                  >
                    Alasan
                  </label>
                  <ValidationProvider
                    #default="{ errors }"
                    :rules="selected.idCard === false ? 'required' : ''"
                  >
                    <BFormTextarea
                      id="reason"
                      v-model="idCard"
                      debounce="300"
                      placeholder="Tuliskan alasan kamu jika menolak"
                      rows="4"
                      :formatter="maxLength"
                      :state="errors.length > 0 || state.idCard ? false : null"
                    />
                  </ValidationProvider>
                  <BRow class="justify-content-end">
                    <small class="mr-1 mt-50">
                      {{ idCard.length }}/300 karakter
                    </small>
                  </BRow>
                </BFormGroup>
              </span>
              <BFormTextarea
                v-else-if="data.ktp_picture.status === 'rejected'"
                v-model="data.ktp_picture.notes"
                disabled
                rows="4"
                class="mt-1"
              />
            </BCard>
          </BCol>
        </BRow>
        <BRow>
          <BCol lg="12">
            <div class="flex flex-row items-center">
              <img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/user.svg"
                alt="Komerce"
              >
              <div class="ml-[10px]">
                <h5 class="text-black font-medium text-xl mb-0">
                  Informasi Data Partner
                </h5>
                <div>
                  <sup class="text-primary">*</sup>
                  <span>Pastikan dokumen yang di-upload persis dengan informasi data partner</span>
                </div>
              </div>
            </div>
          </BCol>
        </BRow>
        <BOverlay
          :show="loadingPartnerInfo"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <BRow class="justify-center items-center mt-3 gap-[10px]">
            <BCol
              lg="12"
              class="flex items-center"
            >
              <BCol
                lg="4"
                class="text-lg"
              >
                Pengguna Product
              </BCol>
              <BCol
                lg="6"
                class="flex items-center"
              >
                <div
                  v-for="(product, key) in partnerInfo.products"
                  :key="key"
                >
                  <img
                    :src="product.url_image"
                    alt="Komerce"
                    width="30"
                  >
                </div>
              </BCol>
            </BCol>
            <BCol
              lg="12"
              class="flex items-center"
            >
              <BCol
                lg="4"
                class="text-lg"
              >
                NIK
              </BCol>
              <BCol lg="6">
                <BInput
                  v-model="partnerInfo.nik"
                  disabled
                />
              </BCol>
            </BCol>
            <BCol
              lg="12"
              class="flex items-center"
            >
              <BCol
                lg="4"
                class="text-lg"
              >
                Nama
              </BCol>
              <BCol lg="6">
                <BInput
                  v-model="partnerInfo.name"
                  disabled
                />
              </BCol>
            </BCol>
            <BCol
              lg="12"
              class="flex items-center"
            >
              <BCol
                lg="4"
                class="text-lg"
              >
                Email
              </BCol>
              <BCol lg="6">
                <BInput
                  v-model="partnerInfo.email"
                  disabled
                />
              </BCol>
            </BCol>
            <BCol
              lg="12"
              class="flex items-center"
            >
              <BCol
                lg="4"
                class="text-lg"
              >
                No. Ponsel
              </BCol>
              <BCol lg="6">
                <BInput
                  v-model="partnerInfo.phone"
                  disabled
                />
              </BCol>
            </BCol>
          </BRow>
        </BOverlay>
        <BRow
          v-if="data.status === 'requested'"
          class="justify-end px-2 gap-7 mt-2"
        >
          <BButton
            variant="outline-primary"
            @click="$router.back()"
          >
            Kembali
          </BButton>
          <BButton
            :disabled="invalid"
            :variant="invalid ? 'secondary' : 'primary'"
            @click="$bvModal.show('modal-verification-kyc')"
          >
            Lanjutkan
          </BButton>
        </BRow>
      </ValidationObserver>
    </BOverlay>
    <BModal
      id="modal-verification-kyc"
      ref="modal-verification-kyc"
      no-stacking
      centered
      hide-footer
      hide-header
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="md"
    >
      <BRow class="justify-center my-1 flex flex-column">
        <BCol class="d-flex justify-content-center">
          <BImg
            src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
          />
        </BCol>
        <BCol class="d-flex justify-content-center mt-1">
          <div class="font-medium text-black text-xl">
            Konfirmasi Verifikasi
          </div>
        </BCol>
        <BCol class="d-flex justify-content-center my-1">
          <div class="font-normal text-black text-base text-center">
            Apakah kamu yakin akan mengubah status verifikasi ini?
          </div>
        </BCol>
        <BRow class="flex flex-row justify-center gap-3 mx-2">
          <BCol class="d-flex justify-content-center">
            <BButton
              block
              size="md"
              variant="outline-primary"
              @click="$bvModal.hide('modal-verification-kyc')"
            >
              Tidak
            </BButton>
          </BCol>
          <BCol class="d-flex justify-content-center">
            <BButton
              block
              size="md"
              :disabled="loadingSubmit"
              :variant="loadingSubmit ? 'secondary' : 'primary'"
              @click="onSubmit"
            >
              <BSpinner
                v-if="loadingSubmit"
                small
                variant="light"
              />
              Iya
            </BButton>
          </BCol>
        </BRow>
      </BRow>
    </BModal>
    <Rules />
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { newAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import { DAY_MONTH_YEAR, TIME } from '@/libs/filterDate'
import Rules from './Rules.vue'
import {
  optionsRadioSelfie, getClass, getLabelStatus, getClassStatus,
} from './config'

export default {
  components: { Rules, ValidationProvider, ValidationObserver },
  data() {
    return {
      required,
      getClass,
      getLabelStatus,
      getClassStatus,
      DAY_MONTH_YEAR,
      TIME,
      loading: false,
      loadingSubmit: false,
      loadingPartnerInfo: false,
      options: optionsRadioSelfie,
      state: {
        selfie: null,
        idCard: null,
      },
      selected: {
        selfie: true,
        idCard: true,
      },
      selfie: '',
      idCard: '',
      idVerif: this.$route.params.id,
      partnerId: this.$route.params.id_partner,
      data: {},
      alertError,
      alertSuccess,
      partnerInfo: {},
    }
  },
  mounted() {
    this.getFile()
    this.getPartnerInfo()
  },
  methods: {
    async getFile() {
      this.loading = true
      const url = `/auth/api/v1/admin/ktp-verification/${this.idVerif}/latest-history`
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.data = data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.$toast_error({ message: 'Gagal loda data' })
        })
    },
    async onSubmit() {
      this.loadingSubmit = true
      const payload = {
        picture_ktp_with_face: {
          notes: this.selfie,
          is_approval: this.selected.selfie,
        },
        picture_ktp: {
          notes: this.idCard,
          is_approval: this.selected.idCard,
        },
      }
      const url = `/auth/api/v1/admin/ktp-verification/${this.idVerif}/approval`
      await newAxiosIns.post(url, payload)
        .then(() => {
          this.loadingSubmit = false
          this.$router.push('/verification-ktp')
          this.alertSuccess('Kamu berhasil mengupdate berkas pengajuan verifikasi KTP')
          this.$bvModal.hide('modal-verification-kyc')
        })
        .catch(() => {
          this.loadingSubmit = true
          this.$toast_error({ message: 'Ada kesalahan saat update berkas pengajuan verifikasi KTP' })
        })
    },
    async getPartnerInfo() {
      this.loadingPartnerInfo = true
      const url = `/auth/api/v1/admin/ktp-verification/user/${this.partnerId}/info`
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.loadingPartnerInfo = false
          this.partnerInfo = data
        })
        .catch(() => {
          this.loadingPartnerInfo = false
          this.$toast_error({ message: 'Gagal load data info partner' })
        })
    },
    maxLength(e) {
      return String(e).substring(0, 300)
    },
    handleChange(evt) {
      this.selectedSelfie = evt
    },
    handleVisibleStatus(status) {
      if (status === 'approved') return false
      return true
    },
  },
}
</script>
